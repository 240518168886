import { authKit } from "./../service/auth";

type ApiError = {
  message: string;
  error: string;
  statusCode: number;
};
async function getAccessToken() {
  const token = await authKit.getAccessToken();
  return token;
}

export async function getRequest<T>(path: string): Promise<T> {
  const response = await fetch(`${import.meta.env.VITE_API_URL}${path}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return response.json() as T;
}

export async function postRequest<T>(
  path: string,
  data: any = null,
): Promise<T> {
  const response = await fetch(`${import.meta.env.VITE_API_URL}${path}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    body: data ? JSON.stringify(data) : null,
  });
  return response.json();
}

export async function putRequest<T>(
  path: string,
  data: any,
): Promise<T | ApiError> {
  const response = await fetch(`${import.meta.env.VITE_API_URL}${path}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    body: JSON.stringify(data),
  });
  return response.json();
}

export async function patchRequest<T>(path: string, data: any): Promise<T> {
  const response = await fetch(`${import.meta.env.VITE_API_URL}${path}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    body: JSON.stringify(data),
  });
  return response.json();
}

export async function deleteRequest<T>(path: string): Promise<T> {
  const response = await fetch(`${import.meta.env.VITE_API_URL}${path}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return response.ok ? null : response.json();
}
