import { createClient } from "@workos-inc/authkit-js";

// if windows context
let authKit: Awaited<ReturnType<typeof createClient>>;
if (typeof window !== "undefined") {
  authKit = await createClient(import.meta.env.VITE_WORKOS_CLIENT_ID, {
    apiHostname: "api.workos.com",
    redirectUri: import.meta.env.VITE_WORKOS_REDIRECT_URI,
    // token is not being stored in local storage
    // in memory. Some library issue
    devMode: true,
    /*onBeforeAutoRefresh: () => {
      console.log("onBeforeAutoRefresh");

      return true;
    },*/
    /*onRefresh: (a) => {
     // console.log("onRefresh", a);

      return true;
    },*/
  });
}

export { authKit };
