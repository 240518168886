export type JWTTokenData = {
  sub: string;
  iat: number;
  exp: number;
  iss: string;
  sid: string;
  jti: string;
  org_id?: string;
  role?: string;
};

export function parseJwt(token: string): JWTTokenData {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );

  return JSON.parse(jsonPayload);
}
