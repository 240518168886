import { authKit } from "@/service/auth";
import { UserInviteBody } from "@/types";
import { deleteRequest, getRequest, postRequest } from "./base.api";

export type LoggedInUser = {
  id: string;
  role: string;
  firstName: string | null;
  lastName: string | null;
};

type AuthenticateUserBody = {
  email: string;
  emailVerified: boolean;
  ssoId: string;
  firstName: string | null;
  lastName: string | null;
  profilePictureUrl: string | null;
};

export type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  profilePictureUrl: string;
  role: string;
  projects: string[];
  active: boolean;
  organizationId: string | null;
};

export async function authenticateUser(data: AuthenticateUserBody) {
  return postRequest<User>(`/users/auth`, data);
}

export async function getUsers() {
  return getRequest<User[]>(`/users`);
}

export async function getLoggedInUser(): Promise<LoggedInUser> {
  const user = authKit.getUser();

  if (!user) {
    throw new Error("User not logged in");
  }

  return {
    id: user.id,
    role: "user",
    firstName: user.firstName,
    lastName: user.lastName,
  };
}

export function addUserToProject(
  userId: string,
  projectId: string,
): Promise<User> {
  return postRequest(`/users/${userId}/project/${projectId}`);
}

export function removeUserFromProject(
  userId: string,
  projectId: string,
): Promise<User> {
  return deleteRequest(`/users/${userId}/project/${projectId}`);
}

export async function inviteUser(params: UserInviteBody): Promise<User> {
  return postRequest<User>(`/users/invite`, params);
}
