import { useEffect } from "react";
import { authenticateUser } from "@/api/user";
import { authKit } from "@/service/auth";
import { useUserStore } from "@/stores/user";
import { parseJwt } from "@/helpers/auth";
import { useShallow } from "zustand/shallow";

let authInitialized = false;

export function useAuthentication() {
  const { setUser, user } = useUserStore(useShallow((s) => s));

  useEffect(() => {
    initAuth();
  }, []);

  async function initAuth() {
    if (user !== undefined || authInitialized) {
      return;
    }
    authInitialized = true;
    const SsoUser = await authKit.getUser();
    if (!SsoUser) {
      await authKit.signIn();
    }
    const token = await authKit.getAccessToken();
    const tokenData = parseJwt(token as string);

    if (!SsoUser) {
      setUser(null);
      throw new Error("User not logged in");
    }
    const authUser = await authenticateUser({
      email: SsoUser.email,
      emailVerified: SsoUser.emailVerified,
      ssoId: SsoUser.id,
      firstName: SsoUser.firstName,
      lastName: SsoUser.lastName,
      profilePictureUrl: SsoUser.profilePictureUrl,
    });

    if (
      authUser.organizationId &&
      tokenData.org_id !== authUser.organizationId
    ) {
      await authKit.switchToOrganization({
        organizationId: authUser.organizationId,
      });
    }
    setUser(authUser);
  }

  return user;
}
