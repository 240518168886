import { RouterProvider, createRouter } from "@tanstack/react-router";

import { routeTree } from "@/routeTree.gen";
import { authKit } from "@/service/auth";
import { useAuthentication } from "@/hooks/useAuthentication";
import { useEffect } from "react";

const router = createRouter({ routeTree });

function App() {
  const user = useAuthentication();

  useEffect(() => {
    if (user === null) {
      authKit.signIn();
    }
  }, [user]);

  if (user === undefined) {
    return <div>Loading...</div>;
  }

  return <RouterProvider router={router} />;
}

export default App;
